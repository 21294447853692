<template>
  <el-row>
    <div class="main-width-80">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item
          ><a href="/send-receive/send-overseas"
            >Send & Receive</a
          ></el-breadcrumb-item
        >
        <el-breadcrumb-item>Sending Overseas</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="shop-list-title">
        <h1>About Us</h1>
        <!-- <p>
          Whether you are shopping within Singapore or overseas, we got all your
          shopping needs covered.
        </p> -->
      </div>
      <ul class="shop-list">
        <li v-for="item in 4" :key="item">
          <h2>Stamps and Gifts</h2>
          <div class="img-div">
            <img
              src="https://www.singpost.com/sites/default/files/2020-07/financial-service-1.jpg"
              alt=""
            />
          </div>

          <p>
            Whether you are shopping within Singapore or overseas, we got all
            your shopping needs covered.
          </p>
          <ul class="links-vertical-ul">
            <li class="links-vertical">
              <i class="icon-right"></i>
              <a href="">Buy Insurance with AXA@POST</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </el-row>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
 @import "../assets/less/common.less";

.shop-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    width: 32%;
    margin-right: 1%;
    margin-bottom: 25px;

    h2 {
      font-size: 24px;
      font-weight: 600;
      text-align: left;
      font-weight: 500;
      margin-bottom: 15px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #4982ef;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }
    .img-div {
      position: relative;
      img {
        width: 100%;
      }
    }
  }
}
.shop-list-title {
  text-align: left;
  margin-bottom: 10px;
  margin-top:20px;
  h1 {
    font-weight: 300;
    font-style: normal;
    color: #24549d;
    font-size: 30px;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
  }
}
.links-vertical-ul {
  padding-top: 15px;
  .links-vertical {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    text-align: left;
    a {
      color: #3982ea;
      font-size: 16px;
      font-weight: 500;
    }
    .icon-right {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-top: 3px solid #d6e1e7;
      border-right: 3px solid #d6e1e7;
      transform: rotate(45deg);
      border-radius: 1px;
      margin-right: 15px;
    }
  }
}
</style>
